import {
  TableContainer,
  Box,
  Typography,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Stack,
} from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import styles from './TableView.styles';

type TableAvaliationSection = {
  name: string;
  headers: string[];
  type: string[];
  values: string[][];
};

type TableAvaliation = TableAvaliationSection[];

type Props = {
  data: TableAvaliation;
  comp?: {
    cz?: {
      name: string;
      val: { [key: string]: string };
    }[];
  };
};

const FLAT_TYPES_WHEN_EQUAL = true;

const TableView: FC<Props> = ({ data, comp }) => {
  const czLabels = comp?.cz;
  const uniqueTypes = (data || []).reduce((acm: boolean, d, _, arr) => {
    if (!acm) return acm;
    return (arr[0].type || []).every((e) => d.type.includes(e));
  }, true);
  

  return (
    <Stack
      {...utils.stack.flex.row('flex-start', 'flex-start')}
      sx={{ width: '100%', overflowX: 'auto' }}
    >
      {(data ? data : []).map((avaliation, J) => {
        const ONLY_VALUES=avaliation.type?.length<=0 && avaliation.headers?.length<=0;
        return (
          (avaliation.name.indexOf('PROGÊNIE') == -1 &&
          <TableContainer sx={styles.tableContainer} key={J}>
            <Box
              sx={{ width: '100%', ...utils.sx.flex.col('center', 'center') }}
            >
              <Typography variant="h3" sx={styles.tableSectionHeader}>
                {avaliation.name}
              </Typography>
            </Box>
            <Table sx={{ minWidth: '200px', border: 'none !important' }}>
              <TableHead>
                <TableRow>
                  {(!ONLY_VALUES && (J === 0 ||
                    (J > 0 && (!FLAT_TYPES_WHEN_EQUAL || !uniqueTypes)))) && (
                    <TableCell></TableCell>
                  )}
                  {avaliation.headers.map((header) => (
                    <TableCell sx={styles.tableCellHeader} key={header}align="center">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ONLY_VALUES && avaliation.values.map((row, index) => (<TableCell sx={styles.tableCellValue} align="right" key={index}>
                  {row}
                </TableCell>))}
                {avaliation.type.map((type, i, arr) => [
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child th': { border: 0 },
                    }}
                  >
                    {(J === 0 ||
                      (J > 0 && (!FLAT_TYPES_WHEN_EQUAL || !uniqueTypes))) && (
                      <TableCell align="center" sx={styles.tableLineType}>
                        {type}
                      </TableCell>
                    )}
                    {avaliation.values.map((row) => (
                      <TableCell sx={styles.tableCellValue} align="right" key={row[i]}>
                        {row[i]}
                      </TableCell>
                    ))}
                  </TableRow>,
                  ...(arr.length - 1 === i &&
                  czLabels &&
                  Array.isArray(czLabels) &&
                  czLabels.length > 0 &&
                  czLabels.findIndex((e) => e.name === avaliation.name) !== -1
                    ? [
                      <Typography variant="body2">
                        {`N° de filhos: ${
                          czLabels.find((e) => e.name === avaliation.name)
                            ?.val?.FILHOS || '-'
                        } N° de rebanhos: ${
                          czLabels.find((e) => e.name === avaliation.name)
                            ?.val?.REB || '-'
                        }`}
                      </Typography>,
                    ]
                    : []),
                ])}
              </TableBody>
            </Table>
          </TableContainer>)
        );
      })}
    </Stack>
  );
};

export default TableView;
