import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Img } from 'components/common';
import { actions } from 'rdx';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Segment } from 'types';
import utils from 'utils';
import styles from './SegmentSelector.styles';

const SegmentSelector: FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goTo = (segment: Segment) => {
    dispatch(actions.scope.changeSegment(segment));
    navigate(`/segment/${utils.segmentTranslator(segment, 'pathlike')}`);
  };
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack
      {
        ...utils.stack.flex[gtMd ? 'row' : 'col'](
          (gtMd ? 'space-between' : 'center') as any,
          'center'
        )
      }
      spacing={ 3 }
      sx={ styles.container }
    >
      <Stack
        { ...utils.stack.flex.col('flex-start', 'flex-start') }
        sx={ styles.btnsContainer }
      >
        <Stack
          { ...utils.stack.flex.row('flex-start', 'center') }
          spacing={ 3 }
          sx={ styles.textLayer  }
        >
          <Img url='/icons/meat.svg' sx={{ width: '24px' }}></Img>
          <Typography variant='h3'>Touros de corte</Typography>
        </Stack>
        <Stack
          { ...utils.stack.flex.row(gtMd ? 'space-between' : 'space-around', 'center') }          
          spacing={ 3 }
          sx={ utils.sx.fw  }
        >
          <Button
            variant='outlined' 
            sx={{ ...utils.sx.fixedSize(120), borderRadius: '2px', 'hover': { backgroundColor: '#DCEAF6' } }}
            onClick={() => (goTo('zc'))}
          >
            Zebu
          </Button>
          <Button 
            variant='outlined' 
            sx={{ ...utils.sx.fixedSize(120), borderRadius: '2px', 'hover': { backgroundColor: '#DCEAF6' } }}
            onClick={() => (goTo('ec'))}
          >
            Taurino
          </Button>
        </Stack>
      </Stack>
      <Stack
        { ...utils.stack.flex.col('flex-start', 'flex-start') }
        sx={ styles.btnsContainer }
      >
        <Stack
          { ...utils.stack.flex.row('flex-start', 'center') }
          spacing={ 3 }
          sx={ styles.textLayer  }
        >
          <Img url='/icons/milk.svg' sx={{ width: '24px' }}></Img>
          <Typography variant='h3'>Touros de leite</Typography>
        </Stack>
        <Stack
          { ...utils.stack.flex.row(gtMd ? 'space-between' : 'space-around', 'center') }
          spacing={ 3 }
          sx={ utils.sx.fw  }
        >
          <Button
            variant='outlined' 
            sx={{ ...utils.sx.fixedSize(120), borderRadius: '2px','hover': { backgroundColor: '#DCEAF6' } }}
            onClick={() => goTo('zl')}
          >
            Zebu
          </Button>
          <Button
            variant='outlined' 
            sx={{ ...utils.sx.fixedSize(120), borderRadius: '2px', 'hover': { backgroundColor: '#DCEAF6' } }}
            onClick={() => goTo('el')}
          >
            Europeu
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SegmentSelector;